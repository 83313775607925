// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-js": () => import("../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-how-we-work-js": () => import("../src/pages/how-we-work.js" /* webpackChunkName: "component---src-pages-how-we-work-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-f-2-c-furniture-js": () => import("../src/pages/portfolio/f2c-furniture.js" /* webpackChunkName: "component---src-pages-portfolio-f-2-c-furniture-js" */),
  "component---src-pages-portfolio-sir-salon-js": () => import("../src/pages/portfolio/sir-salon.js" /* webpackChunkName: "component---src-pages-portfolio-sir-salon-js" */),
  "component---src-pages-portfolio-top-remit-js": () => import("../src/pages/portfolio/top-remit.js" /* webpackChunkName: "component---src-pages-portfolio-top-remit-js" */),
  "component---src-pages-services-js": () => import("../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

